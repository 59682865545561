<script>
import { mapState } from 'vuex';
import Batch from './Batch.vue';
import Input from 'v3/components/Form/Input.vue';

export default {
  components: {
    Batch,
    Input,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('farmako', {
      isNotFound: s => s.batchSearch.isNotFound,
      results: s => s.batchSearch.results,
      isLoading: s => s.batchSearch.isLoading,
      isOpen: s => s.batchSearch.isOpen,
    }),
  },
  methods: {
    onClose() {
      this.$store.commit('farmako/closeBatchSelection');
    },
    async onSelect(batch) {
      const item = this.$store.state.farmako.batchSearch.item;

      const hasNoMoreStrips = batch.quantity < item.size; // Use loose quant when strip isn't available

      const patch = {
        batch: batch.batch,
        quantity: hasNoMoreStrips ? 1 : item.size * 1,
        discount: item.sale_discount ?? 0,
        medicine_id: item.medicine_id,
        rx_required: item.rx_required,
      };

      const existing = this.$store.state.farmako.cart.find(
        i => i.medicine_id === patch.medicine_id && i.batch === patch.batch
      );

      if (existing) {
        patch.quantity =
          patch.quantity + (hasNoMoreStrips ? 1 : Number(item.size));
      }

      await this.$store.dispatch('farmako/patchCartItem', patch);

      this.onClose();
    },
  },
};
</script>

<template>
  <woot-modal :show.sync="isOpen" :on-close="onClose">
    <woot-modal-header
      header-title="Select Medicine Batch"
      :header-content="`Found ${results.length} batches`"
    />
    <div class="flex flex-col p-4">
      <ul
        v-if="results.length"
        id="search-results"
        class="flex-col flex py-2 ml-0 max-h-[60vh] overflow-scroll gap-y-3 divide-y-2 divide-black-900"
      >
        <Batch
          v-for="item in results"
          :name="item.batch"
          :key="item.id"
          :expiry="item.expiry"
          :mrp="item.mrp"
          :quantity="item.quantity"
          :id="item.id"
          :expired="item.expired"
          :margin="item.margin"
          @select="e => onSelect(item)"
          :price_to_retailer="item.price_to_retailer"
          :isAvailable="item.isAvailable"
        />
      </ul>
      <p v-if="isNotFound" class="text-center font-medium mt-2 text-red-400">
        No search results found
      </p>
    </div>
  </woot-modal>
</template>
