<script>
import { mapState } from 'vuex';
import { differenceInMonths, parseISO } from 'date-fns';

export default {
  props: [
    'name',
    'quantity',
    'expiry',
    'id',
    'expired',
    'mrp',
    'margin',
    'price_to_retailer',
    'isAvailable',
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState('farmako', {
      results: s => s.batchSearch.results,
      item: s => s.batchSearch.item,
      cart: s => s.cart,
    }),
    labels() {
      if (!this.isAvailable) return ['alert', 'Not available'];

      const diff = differenceInMonths(parseISO(this.expiry), new Date());

      if (this.expired) return ['warning', 'Expired'];

      if (diff <= 3) {
        return ['warning', 'Expires soon'];
      }

      return ['success', 'Available'];
    },
  },
};
</script>

<template>
  <li
    :class="[
      'flex items-center gap-x-6 px-3 py-2 justify-between rounded-md',
      'bg-black-900',
    ]"
  >
    <div class="truncate w-full grid-cols-2 grid">
      <p class="!text-sm">Batch Name: {{ name }}</p>
      <p class="!text-sm">MRP: {{ mrp }}</p>
      <p class="!text-sm">PTR: {{ price_to_retailer }}</p>
      <p class="!text-sm">Quantity: {{ quantity }}</p>
      <p class="!text-sm">Expiry: {{ expiry }}</p>
      <p class="!text-sm">Margin: {{ margin }}</p>
    </div>
    <div class="flex items-center gap-2 shrink-0">
      <woot-label :color-scheme="labels[0]" :title="labels[1]"></woot-label>
      <woot-button
        :disabled="!isAvailable"
        size="small"
        @click="$emit('select')"
      >
        Select
      </woot-button>
    </div>
  </li>
</template>
