import { client } from './api';
import { changeTab, get, showAlert, startCase } from './util';
import { isAxiosError } from 'axios';
import { router } from 'dashboard/routes/index.js';

export const state = {
  pharmacy_name: 'Unknown Pharmacy',
  prescriptions: [],
  
  customer: null,

  order: {
    billed: false,
    cancelled: false,
    delivery_charge: 0,
  },

  ui: {
    disabled: false,
    processing: false,
  },

  cart: [],

  batchSearch: {
    item: null,
    results: [],
    isLoading: false,
    isNotFound: false,
    isOpen: false,
  },

  search: {
    results: [],
    isLoading: false,
    isNotFound: false,
    isOpen: false,
  },
};

export const getters = {
  pharmacy($state) {
    return $state.pharmacy_name;
  },
  search($state) {
    return $state.search;
  },
  order($state) {
    return $state.order;
  },
  cart($state) {
    return $state.cart;
  },
  customer($state) {
    return $state.customer;
  },
  prescriptions($state) {
    return $state.prescriptions;
  },
  uiFlags($state) {
    return $state.ui;
  },
};

export const actions = {
  async fetchOrderDetails({ rootGetters, state, dispatch }) {
    const conversation = rootGetters.getSelectedChat;
    const user = rootGetters.getCurrentUser;

    client.order_id = conversation.id;

    const attrs = await client.getCustomAttributes(conversation.id);

    state.pharmacy_name = attrs?.serving_pharmacy?.name || 'Unknown Pharmacy';

    const distance =
      Number(attrs?.customer_pharmacy_distance || 0) / 1000 + ' Kms';

    state.order = {
      billed: false,
      cancelled: false,
      delivery_charge: 0,
      doctor: {
        medicines: {},
      },
    };

    state.customer = {};

    state.order.order_id = conversation.id;
    state.order.account_id = conversation.account_id;
    state.order.distance = distance;

    state.order.discount_percentage = get(attrs, 'discount_percentage', 0);

    state.order.delivery_charge = get(attrs, 'delivery_charge', 0) / 100;

    state.customer.address = get(attrs, 'customer_address', '');
    state.customer.name = get(attrs, 'billing_name');

    if (typeof state.customer.name === 'string') {
      const [c, doctor] = state.customer.name.split('RMP');
      state.customer.name = c?.trim();
      state.customer.doctor = doctor?.trim();
    }

    if (user.custom_attributes.agent_type === 'DOCTOR') {
      state.customer.doctor = user.name;
    }

    switch (attrs?.bill_status) {
      case 'billed':
        state.order.billed = true;
        state.ui.disabled = true;
        console.error(`Order Already billed`);
        break;

      case 'cancelled':
        state.order.cancelled = true;
        state.ui.disabled = true;
        console.error(`Order Cancelled`);
        break;

      default:
        state.order.billed = false;
        state.ui.disabled = false;
        state.ui.processing = false;
    }

    await dispatch('fetchCart');
  },

  async fetchCart({ rootGetters, state }) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    const cart = await client.getCart(conversation.id);

    const medicines_meta = {};

    for (const item of cart) {
      const key = `${item.medicine_id}|${item.batch}`;
      medicines_meta[key] = state.order.doctor.medicines[key] ?? {
        dosage: '-',
        frequency: 1,
      };
    }

    state.order.doctor.medicines = medicines_meta;

    state.cart = cart;
  },

  async savePrescription({ rootGetters, state, dispatch }, payload) {
    const conversation = rootGetters.getSelectedChat;

    await dispatch('fetchCart');

    const medicines = state.cart.map(item => {
      const k = `${item.medicine_id}|${item.batch}`;
      const m = state.order.doctor.medicines[k];

      if (m.dosage === '-' || m.frequency === 0) {
        throw new Error(`Invalid dosage or frequency`);
      }

      item.dosage = m.dosage;
      item.frequency = m.frequency;

      return item;
    });

    await client.savePrescription(conversation.id, { ...payload, medicines });
  },

  async searchMedicine({ rootGetters, state }, query) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.search.isLoading = true;
    state.search.isNotFound = false;
    state.search.isOpen = false;

    const items = await client.searchMedicines(query);

    for (const item of items) {
      const existing = state.cart.find(i => i.id === item.medicine_id);

      const availability =
        item.loose_quantity > (existing ? existing.quantity : 0);

      if (existing) existing.isAvailable = availability;

      item.isAvailable = availability;
    }

    state.search.isLoading = false;

    state.search.results = items.sort((a, b) => b.isAvailable - a.isAvailable);

    if (items.length === 0) {
      return (state.search.isNotFound = true);
    }

    state.search.isOpen = true;
  },

  async sendMedicineDetail({ rootGetters, state }, medicine) {
    if (medicine.discontinued === 'yes')
      return alert('Medicine is discontinued - cannot send details');

    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.ui.processing = true;

    await client.sendMedicineDetail(medicine.slug);

    state.ui.processing = false;
    state.search.isOpen = false;
    changeTab(0);
  },

  async searchAlternateMedicine({ rootGetters, state }, id) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.search.isLoading = true;
    state.search.isNotFound = false;
    state.search.isOpen = false;

    const items = await client.searchAlternate(id);

    for (const item of items) {
      const existing = state.cart.find(i => i.id === item.medicine_id);

      const availability =
        item.loose_quantity > (existing ? existing.quantity : 0);

      if (existing) existing.isAvailable = availability;

      item.isAvailable = availability;
    }

    state.search.isLoading = false;

    state.search.results = items.sort((a, b) => b.isAvailable - a.isAvailable);

    if (items.length === 0) {
      return (state.search.isNotFound = true);
    }

    state.search.isOpen = true;
  },

  async addToShortbook({ rootGetters, state }, data) {
    try {
      state.ui.processing = true;

      const res = await client.addToShortbook(data);
    } catch (error) {
      console.log(error);
    } finally {
      state.ui.processing = false;
    }
  },

  async patchCartItem({ rootGetters, state, dispatch }, item) {
    try {
      state.ui.processing = true;

      const conversation = rootGetters.getSelectedChat;

      client.order_id = conversation.id;

      const patch = {
        batch: item.batch,
        discount: item.discount ?? 0,
        medicine_id: item.medicine_id,
        quantity: Number(item.quantity),
      };

      const existing = state.cart.find(
        i => i.medicine_id === patch.medicine_id && i.batch === patch.batch
      );

      if (existing) {
        patch.version = existing.version;
      }

      const next = await client.updateCartItems({
        conversation_id: conversation.id,
        items: [patch],
      });

      state.cart = next;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 409) {
          showAlert(`Cart changed since last update - refetching`);
          await dispatch('fetchCart');
        }
      }
    } finally {
      state.ui.processing = false;
    }
  },

  async selectMedicineBatch({ state, rootGetters }, item) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.search.isOpen = false;

    state.batchSearch.item = item;
    state.batchSearch.isLoading = true;
    state.batchSearch.isOpen = false;
    state.batchSearch.isNotFound = false;

    const results = await client.getMedicineBatches(item.medicine_id);

    for (const result of results) {
      const existing = state.cart.find(
        i => i.id === item.medicine_id && i.batch === result.name
      );

      const available = result.quantity > (existing?.quantity ?? 0);

      result.isAvailable = available;
    }

    state.batchSearch.results = results;
    state.batchSearch.isLoading = false;
    state.batchSearch.isOpen = true;
  },

  async updateOrderDetails({ rootGetters, state }, { customer, order }) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.ui.processing = true;

    const payload = {};

    if (customer) {
      let doctor = customer?.doctor?.trim() ?? '';

      if (doctor.length !== 0) {
        const starts_with_doctor = doctor.toLowerCase().startsWith('dr.');

        if (!starts_with_doctor) {
          doctor = `Dr. ${startCase(doctor.replace('.', '').toLowerCase())}`;
        }

        if (starts_with_doctor) {
          doctor = `Dr. ${startCase(doctor.toLowerCase().substring(3).trim())}`;
        }
      }

      payload.customer_address = customer.address;
      payload.billing_name = customer.doctor
        ? `${customer?.name?.trim()} RMP ${doctor}`
        : customer?.name?.trim();
    }

    if (order) {
      payload.delivery_charge = order.delivery_charge * 100;
    }

    await client.updateCustomAttributes(payload);

    state.ui.processing = false;
  },

  async duplicateBill({ state, rootGetters }) {
    const reason = prompt('Duplicating Your Order!! Enter your reason.');

    if (!reason || reason === '') {
      return alert('enter a valid reason');
    }

    try {
      const conversation = rootGetters.getSelectedChat;

      state.ui.processing = true;

      const data = await client.duplicateOrderVirtualRx({
        reason,
        conversation_id: conversation.id,
      });

      await this.redirect(data.conversation_id);
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      state.ui.processing = false;
    }
  },

  async duplicateOrder({ state, rootGetters }) {
    try {
      const conversation = rootGetters.getSelectedChat;
      state.ui.processing = true;

      const data = await client.duplicateOrder(conversation.id);

      await this.redirect(data.conversation_id);
    } catch (error) {
      console.log(error);
    } finally {
      state.ui.processing = false;
    }
  },

  async cancelOrder({ state, rootGetters }) {
    const reason = prompt('Cancelling Your Order!! Enter your reason.');

    if (!reason || reason === '') {
      return alert('enter a valid reason');
    }

    try {
      const conversation = rootGetters.getSelectedChat;

      state.ui.processing = true;

      const data = await client.cancelOrder({
        reason,
        conversation_id: conversation.id,
      });

      await this.redirect(data.conversation_id);
    } catch (error) {
      console.log(error.message);
    } finally {
      state.ui.processing = false;
    }
  },

  async sendDraft({ state, rootGetters }) {
    state.ui.processing = true;

    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id;

    const placed = await client.sendDraftBill(
      conversation.id,
      conversation.account_id
    );

    if (!placed) return;

    changeTab(0);

    state.ui.processing = false;
  },

  async updatePrescription({ state, rootGetters }) {
    state.ui.processing = true;

    const conversation = rootGetters.getSelectedChat;
    payload.order_id = conversation.id.toString();

    const placed = await client.placeEvitalOrder(
      conversation.id,
      conversation.account_id
    );

    if (!placed) return;

    changeTab(0);

    state.ui.processing = false;
  },

  placeCall({ state, rootGetters, dispatch }, payload) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id.toString();
    return client.placeCall(payload);
  },

  searchCustomer({ rootGetters }, phone) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id.toString();
    return client.getCustomerFromPhone(phone);
  },

  createOrder({ rootGetters }, payload) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id.toString();
    return client.createOrder(payload);
  },

  getCustomerOrders({ rootGetters }, phone) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id.toString();
    return client.getCustomerOrders(phone);
  },

  async getCustomerPrescriptions({ state, rootGetters }) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id;
    const items = await client.getPrescriptions(conversation.id);
    return items;
  },

  async setPrescriptionStatus({ state, rootGetters }, status) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id;
    return client.updatePrescriptionStatus(status);
  },

  async setPrescriptionItemStatus({ state, rootGetters }, payload) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id;
    return client.updatePrescriptionItemStatus(payload.id, payload.status);
  },

  async getConsultations({ rootGetters }) {
    try {
      const conversation = rootGetters.getSelectedChat;
      client.order_id = conversation.id;
      return await client.getConsultationsByOrderID();
    } catch (err) {
      if (isAxiosError(err)) {
        throw new Error(err.response?.data.message);
      }

      throw err;
    }
  },

  async createConsultation({ rootGetters }, phone) {
    try {
      const conversation = rootGetters.getSelectedChat;
      client.order_id = conversation.id;
      return await client.createConsultation(phone);
    } catch (err) {
      if (isAxiosError(err)) {
        throw new Error(err.response?.data.message);
      }

      throw err;
    }
  },

  async getCSDetails({ rootGetters }) {
    const conversation = rootGetters.getSelectedChat;
    client.order_id = conversation.id;

    const { payload } = await client.getAgentDetails();
    console.log(payload);
    const attrs = payload.data.custom_attributes;
    return { phone: attrs.phone, type: attrs.agent_type };
  },

  async callCustomer({ dispatch }) {
    try {
      const details = await dispatch('getCSDetails');
      showAlert('Placing call...');

      await dispatch('placeCall', {
        receiver_type: 'CUSTOMER',
        caller_phone: details.phone,
        caller_type: details.type,
      });

      showAlert('Call placed successfully');
    } catch (err) {
      console.error(err);
      showAlert(err?.response?.data?.message || 'Failed to place call');
    }
  },

  async callRider({ dispatch }) {
    try {
      const details = await dispatch('getCSDetails');
      showAlert('Placing call...');
      await dispatch('placeCall', {
        receiver_type: 'LOGISTICS_PARTNER',
        caller_phone: details.phone,
        caller_type: details.type,
      });
      showAlert('Call placed successfully');
    } catch (err) {
      console.error(err);
      showAlert(err?.response?.data?.message || 'Failed to place call');
    }
  },

  redirect({}, id) {
    showAlert(`Redirecting to conversation: ${id}`);

    const url = window.parent.location.pathname;

    const target = url.split('/').slice(0, -1).join('/') + `/${id}`;

    return router.push(target);
  },
};

export const mutations = {
  clearSearch(state) {
    state.search.results = [];
    state.search.isLoading = false;
    state.search.isNotFound = false;
    state.search.isOpen = false;
  },
  closeBatchSelection(state) {
    state.batchSearch.results = [];
    state.batchSearch.isOpen = false;
    state.batchSearch.isLoading = false;
    state.batchSearch.isNotFound = false;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
