<script setup>
import { debounce } from '@chatwoot/utils';
import { useStore, useMapGetter } from 'dashboard/composables/store.js';
import { useAlert } from 'dashboard/composables';
import {
  computed,
  defineProps,
  effect,
  reactive,
  watch,
  watchEffect,
} from 'vue';

const store = useStore();

const customer = useMapGetter('farmako/customer');
const ui = useMapGetter('farmako/uiFlags');
const cart = useMapGetter('farmako/cart');
const order = useMapGetter('farmako/order');

const state = reactive({
  customer: {
    name: '',
    address: '',
    doctor: '',
  },
});

const handleOrderDetailsChange = debounce(({ customer, order }) => {
  console.log(customer);
  return store.dispatch('farmako/updateOrderDetails', {
    customer,
    order,
  });
}, 1000);

watch(
  customer,
  async data => {
    console.log('data', data);
    state.customer = {
      ...state.customer,
      ...data,
    };
  },
  { deep: true }
);

const isDoctorNameRequired = computed(() =>
  cart.value.some(item => item.rx_required)
);

const total = computed(() => {
  const value = cart.value.reduce((acc, item) => {
    acc += parseInt((item.mrp * item.quantity).toString());
    return acc;
  }, 0);

  return (value / 100).toFixed(2);
});

const discount = computed(() => {
  const value = cart.value.reduce((acc, item) => {
    acc += item.discount;
    return acc;
  }, 0);

  return (value / 100).toFixed(2);
});

const netPayable = computed(() => {
  return (total.value - discount.value + order.value.delivery_charge).toFixed(2);
});

const disabled = computed(
  () => order.value.billed || ui.value.processing || ui.value.disabled
);

const onSubmit = async () => {
  if (!state.customer.name) {
    return useAlert(`Customer name is required`);
  }

  if (isDoctorNameRequired.value && !state.customer.doctor) {
    return useAlert(`Doctor name is required`);
  }
  console.log(state.customer);
  await handleOrderDetailsChange({
    customer: state.customer,
    order: order.value,
  });

  await useAlert(`Saving order...`);
  await store.dispatch('farmako/sendDraft');
  await useAlert(`Draft sent`);
};

const onReOrder = async () => {
  store.dispatch('farmako/duplicateOrder');
};

const onCancel = () => {
  store.dispatch('farmako/cancelOrder');
};

const onDuplicate = () => {
  store.dispatch('farmako/duplicateBill');
};

const onCustomerChange = () => {
  return handleOrderDetailsChange({ customer: state.customer, order });
};
</script>

<style>
label > input {
  margin-bottom: 0 !important;
}

label {
  display: flex !important;
  align-items: center !important;
}
</style>

<template>
  <section class="flex flex-col rounded-md dark:bg-black-900 p-4">
    <div v-if="customer && order">
      <form @submit.prevent="onSubmit" class="grid grid-cols-2 gap-3">
        <label for="name">Patient Name</label>
        <woot-input
          type="text"
          required
          id="name"
          name="name"
          :readonly="disabled"
          v-model="state.customer.name"
          @input="onCustomerChange"
          placeholder="Enter Patient name"
        />

        <label for="address">Address</label>
        <woot-input
          type="text"
          id="address"
          name="address"
          :readonly="disabled"
          v-model="state.customer.address"
          @input="onCustomerChange"
          placeholder="Enter Address"
        />
        <label for="doctor">Doctor Name</label>
        <woot-input
          type="text"
          required
          id="doctor"
          name="doctor"
          :readonly="disabled"
          v-model="state.customer.doctor"
          @input="onCustomerChange"
          placeholder="Enter Doctor name"
        />

        <woot-button
          type="submit"
          size="small"
          :color-scheme="disabled ? 'secondary' : 'success'"
          :disabled="disabled"
          class="btn btn-info col-span-2 mx-auto mt-2 max-w-max px-12 py-2.5 disabled:bg-gray-300"
        >
          {{ ui.processing ? 'Saving' : disabled ? 'Billed' : 'Save order' }}
        </woot-button>
        <div>
          <div>
            Distance: {{ order.distance }}, Delivery Charge:
            {{ order.delivery_charge }}
          </div>
          <div class="whitespace-nowrap">
            Total:
            <span class="text-green-500"
              >₹{{ total }} - ₹{{ discount }} = ₹{{ netPayable }}</span
            >
          </div>
        </div>
      </form>
    </div>

    <div class="flex justify-between w-full mt-6">
      <woot-button
        @click="onDuplicate"
        size="small"
        :disabled="ui.processing"
        color-scheme="primary"
        class="btn btn-info col-span-2 max-w-max px-12 py-3 disabled:bg-gray-300"
      >
        Duplicate Bill
      </woot-button>

      <woot-button
        @click="onCancel"
        size="small"
        :disabled="ui.processing"
        color-scheme="alert"
      >
        Cancel
      </woot-button>
      <woot-button
        @click="onReOrder"
        size="small"
        :disabled="ui.processing"
        color-scheme="primary"
      >
        Re-Order
      </woot-button>
    </div>
  </section>
</template>
