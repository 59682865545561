<script setup>
import { computed, ref } from 'vue';
import SearchResult from './SearchResult.vue';
import Input from 'v3/components/Form/Input.vue';
import { useStore, useMapGetter } from 'dashboard/composables/store.js';
import { debounce } from '@chatwoot/utils';
import WootModal from 'dashboard/components/Modal.vue';
import WootModalHeader from 'dashboard/components/ModalHeader.vue';

const input = ref();
const store = useStore();

const search = useMapGetter('farmako/search');
const ui = useMapGetter('farmako/uiFlags');

const results = computed(() => search.value.results);
const disabled = computed(() => ui.value.billed || ui.value.disabled);

const isLoading = computed(() => search.value.isLoading)
const isNotFound = computed(() => search.value.isNotFound)

const handleSearch = debounce(value => {
  const query = value.toString().trim();

  if (query.length < 3) return store.commit('farmako/clearSearch');

  return store.dispatch('farmako/searchMedicine', query);
}, 350);

const onSearch = e => handleSearch(e.target.value);

const onAddToCart = data => {
  store.dispatch(`farmako/selectMedicineBatch`, data);
};

const onGetAlternate = item => {
  store.dispatch('farmako/searchAlternateMedicine', item.content);
};

const onAddToShortbook = data => {
  store.dispatch('farmako/addToShortbook', data);
};

const onClose = () => {
  store.commit('farmako/clearSearch');
};

const onSendMedicineDetail = item => {
  store.dispatch('farmako/sendMedicineDetail', item);
};
</script>

<template>
  <div class="mt-4">
    <div class="relative">
      <Input
        ref="input"
        autofocus
        type="text"
        placeholder="Search medicines"
        name="med-search"
        :readonly="disabled"
        class="w-full"
        @input="onSearch"
      />
      <svg
        id="search-spinner"
        class="pointer-events-none absolute right-4 top-2 animate-spin"
        :class="[isLoading ? '' : 'hidden']"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="8.5"
          stroke="url(#paint0_radial_9_315)"
          stroke-width="3"
        />
        <defs>
          <radialGradient
            id="paint0_radial_9_315"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(10 1.5) rotate(90) scale(17)"
          >
            <stop offset="0.244792" stop-color="#F9FCFF" stop-opacity="0" />
            <stop offset="1" stop-color="#3F99F7" stop-opacity="0.65" />
          </radialGradient>
        </defs>
      </svg>

      <p
        v-if="isNotFound"
        class="text-center text-lg font-medium mt-2 text-red-400"
      >
        No search results found
      </p>
    </div>

    <WootModal
      v-model:show="search.isOpen"
      :on-close="onClose"
      size="!w-full !max-w-[50rem]"
    >
      <WootModalHeader
        header-title="Medicine Search Results"
        :header-content="`Found ${results.length} medicines`"
      />
      <section class="flex flex-col rounded-lg p-5">
        <ul
          v-if="results.length"
          id="search-results"
          class="flex-col flex py-2 max-h-[60vh] overflow-scroll gap-5"
        >
          <SearchResult
            v-for="item in results"
            :id="item.medicine_id"
            :key="item.medicine_id"
            :name="item.medicine_name"
            :content="item.content"
            :price="item.mrp"
            :packing_size="item.packing_size"
            :item="item"
            :is_rx_required="item.is_rx_required"
            :manufacturer_name="item.manufacturer_name"
            :mrp="item.mrp"
            :quantity="item.loose_quantity"
            :is-available="item.isAvailable"
            @get-alternate="onGetAlternate"
            @add-to-cart="onAddToCart"
            @add-to-shortbook="onAddToShortbook"
            @send-medicine-detail="onSendMedicineDetail"
          />
        </ul>
      </section>
    </WootModal>
  </div>
</template>
