<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import FarmakoCartApp from './Farmako/Cart/index.vue';
import FarmakoNewOrder from './Farmako/NewOrder.vue';
import FarmakoSearchCustomer from './Farmako/SearchCustomer.vue';
import FarmakoPrescription from './Farmako/Prescription.vue';
import FarmakoTeleconsultation from './Farmako/Teleconsultation.vue';

export default {
  components: {
    LoadingState,
    FarmakoCartApp,
    FarmakoNewOrder,
    FarmakoSearchCustomer,
    FarmakoPrescription,
    FarmakoTeleconsultation,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    config: {
      type: Array,
      default: () => [],
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hasOpenedAtleastOnce: false,
      iframeLoading: true,
    };
  },
  computed: {
    dashboardAppContext() {
      return {
        conversation: this.currentChat,
        contact: this.$store.getters['contacts/getContact'](this.contactId),
        currentAgent: this.currentAgent,
      };
    },
    contactId() {
      return this.currentChat?.meta?.sender?.id;
    },
    currentAgent() {
      const { id, name, email } = this.$store.getters.getCurrentUser;
      return { id, name, email };
    },
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        this.hasOpenedAtleastOnce = true;
      }
    },
  },
  mounted() {
    setTimeout(() => this.hasOpenedAtleastOnce = true, 200)
    window.addEventListener('message', this.triggerEvent);
  },
  unmounted() {
    window.removeEventListener('message', this.triggerEvent);
  },
  methods: {
    triggerEvent(event) {
      if (!this.isVisible) return;
      if (event.data === 'chatwoot-dashboard-app:fetch-info') {
        this.onIframeLoad(0);
      }
    },
    getFrameId(index) {
      return `dashboard-app--frame-${this.position}-${index}`;
    },
    onIframeLoad(index) {
      // A possible alternative is to use ref instead of document.getElementById
      // However, when ref is used together with v-for, the ref you get will be
      // an array containing the child components mirroring the data source.
      const frameElement = document.getElementById(this.getFrameId(index));
      const eventData = { event: 'appContext', data: this.dashboardAppContext };
      frameElement.contentWindow.postMessage(JSON.stringify(eventData), '*');
      this.iframeLoading = false;
    },
    isInternalApp(type) {
      const t = this.title.toLowerCase();

      const appList = [
        'order',
        'new order',
        'search phone',
        'prescription',
        'consultations',
      ];

      const allowed = appList.includes(t);

      if (type) return allowed && t === type;

      return allowed;
    },
    onLoadInternalAppLoad() {
      this.hasOpenedAtleastOnce = true;
      this.iframeLoading = false;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div
    v-if="hasOpenedAtleastOnce"
    class="dashboard-app--container"
  >
    <div
      v-for="(configItem, index) in config"
      :key="index"
      class="dashboard-app--list"
    >
      <FarmakoCartApp
        v-if="isInternalApp('order')"
        @load="() => onLoadInternalAppLoad()"
      />
      <FarmakoNewOrder
        v-else-if="isInternalApp('new order')"
        @load="() => onLoadInternalAppLoad()"
      />
      <FarmakoSearchCustomer
        v-else-if="isInternalApp('search phone')"
        @load="() => onLoadInternalAppLoad()"
      />
      <FarmakoPrescription
        v-else-if="isInternalApp('prescription')"
        @load="() => onLoadInternalAppLoad()"
      />
      <FarmakoTeleconsultation
        v-else-if="isInternalApp('consultations')"
        @load="() => onLoadInternalAppLoad()"
      />
      <template v-else>
        <LoadingState
          v-if="iframeLoading"
          :message="$t('DASHBOARD_APPS.LOADING_MESSAGE')"
          class="dashboard-app_loading-container"
        />
        <iframe
          v-if="configItem.type === 'frame' && configItem.url"
          :id="getFrameId(index)"
          :src="configItem.url"
          @load="() => onIframeLoad(index)"
        />
      </template>
    </div>
  </div>
</template>

<style scoped>
.dashboard-app--container,
.dashboard-app--list,
.dashboard-app--list iframe {
  height: 100%;
  width: 100%;
}

.dashboard-app--container {
  overflow: auto;
}

.dashboard-app--list iframe {
  border: 0;
}

.dashboard-app_loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
