<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useMapGetter } from 'dashboard/composables/store.js';

const store = useStore();

const state = reactive({
  cart: {
    verification_status: 'LOADING',
  },
  prescriptions: [],
});

const hasAnyPendingPrescriptions = computed(
  () => state.prescriptions.filter(p => p.status === 'PENDING').length
);

const onFetchDetails = async () => {
  const { cart, prescriptions } = await store.dispatch(
    'farmako/getCustomerPrescriptions'
  );

  state.cart = cart;
  state.prescriptions = prescriptions;
};

onMounted(() => {
  onFetchDetails();
});

const updatePrescriptionStatus = async status => {
  await store.dispatch('farmako/setPrescriptionStatus', status);
  await onFetchDetails();
};

const updatePrescriptionItemStatus = async (id, status) => {
  await store.dispatch('farmako/setPrescriptionItemStatus', { id, status });
  await onFetchDetails();
};
</script>

<template>
  <main class="overflow-scroll min-h-screen">
    <h1 class="text-3xl font-bold text-center py-5">Verify Prescriptions</h1>
    <h1 class="text-center font-medium text-2xl">
      {{ state.cart.verification_status }}
    </h1>

    <div
      v-if="state.prescriptions.length === 0"
      class="text-xl font-bold text-center mt-10"
    >
      No prescriptions to verify
    </div>
    <template v-else>
      <div class="flex flex-col mt-10 gap-5 max-w-screen-md mx-auto">
        <div
          class="flex gap-5 items-center justify-between bg-black-900 overflow-clip rounded-md"
          :key="i.id"
          v-for="i in state.prescriptions"
        >
          <div
            class="w-52 object-cover object-center aspect-square flex flex-col relative justify-center items-center"
          >
            <iframe
              :src="i.signed_url"
              class="h-full w-full"
              frameborder="0"
            ></iframe>
            <a
              :href="i.signed_url"
              target="_blank"
              class="text-white absolute inset-0 grid place-content-center bg-n-black/60 text-xs cursor-pointer"
              >View File</a
            >
          </div>
          <div class="text-sm px-4 py-3">
            <div class="text-sm">
              Status:
              <span
                :class="
                  i.status === 'PENDING'
                    ? 'text-yellow-500 font-bold'
                    : i.status === 'APPROVED'
                      ? 'text-green-500 font-bold'
                      : 'text-red-500 font-bold'
                "
                >{{ i.status }}</span
              >
            </div>
            <div class="text-sm">
              Submitted: {{ new Date(i.created_at).toLocaleString('en-IN') }}
            </div>
            <div class="text-sm">
              Updated At: {{ new Date(i.updated_at).toLocaleString('en-IN') }}
            </div>
          </div>
          <div class="flex gap-2 px-4 py-3">
            <woot-button
              color-scheme="primary"
              @click="updatePrescriptionItemStatus(i.id, 'APPROVED')"
              :disabled="i.status === 'APPROVED'"
            >
              Verify
            </woot-button>
            <woot-button
              @click="updatePrescriptionItemStatus(i.id, 'REJECTED')"
              :disabled="i.status === 'REJECTED'"
              color-scheme="alert"
            >
              Reject
            </woot-button>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4 items-center mt-10">
        <div
          v-if="!hasAnyPendingPrescriptions"
          class="flex gap-5 w-full justify-center"
        >
          <woot-button
            @click="updatePrescriptionStatus('VERIFIED')"
            color-scheme="success"
            class="bg-blue-500 text-white py-2 px-4 rounded-md text-sm"
          >
            Approve Cart
          </woot-button>

          <woot-button
            @click="updatePrescriptionStatus('REJECTED')"
            color-scheme="alert"
            class="py-2 px-4 rounded-md text-sm"
          >
            Reject Cart
          </woot-button>
        </div>
      </div>
    </template>
  </main>
</template>
