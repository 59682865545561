<script setup>
import { debounce } from '@chatwoot/utils';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useMapGetter } from 'dashboard/composables/store.js';

const props = defineProps(['item']);

const store = useStore();
const ui = useMapGetter('farmako/uiFlags');

const disabled = computed(() => ui.value.processing || ui.value.disabled);
const total = computed(() => props.item.quantity * props.item.mrp);

const strip_quantity = computed(() => {
  const value = Number(props.item.quantity / props.item.size);

  return value.toFixed(0);
});

const discount_amount = computed(() => props.item.discount || 0); // In Paisa

const discount_amount_in_rupee = computed(() => {
  return (discount_amount.value / 100).toFixed(2);
});

const discount_percentage = computed(() => {
  const percent = (discount_amount.value * 100) / total.value;

  return percent.toFixed(2);
});

const onQuantityChange = debounce((value, item) => {
  if (value < 1) value = 1;

  if (value > item.stock) {
    alert(`Available stock (Loose): ${item.stock}`);
    value = item.stock;
  }

  item.quantity = Number(value);
  console.log(value);
  store.dispatch(`farmako/patchCartItem`, {
    ...item,
    discount: value,
  });
}, 1000);

const onDelete = item => {
  store.dispatch(`farmako/patchCartItem`, {
    ...item,
    quantity: 0,
  });
};

const onShowInfo = item => {
  alert(`${item.name}
          in stock:   ${item.stock}
          batch:    ${item.batch}
          by:   ${item.manufacturer}
          salt:   ${item.salt}
          packing size:   ${item.size_string}
          mrp: ${(item.mrp_pack / 100).toFixed(2)}`);
};
</script>

<template>
  <section class="w-full grid grid-container-custom gap-x-5">
    <div class="flex gap-x-2 items-center">
      <button
        class="h-5 w-5 cursor-pointer flex-shrink-0 button smooth button--only-icon small alert"
        @click="onDelete(item)"
      >
        <svg
          width="14"
          height="14"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon--font delete"
        >
          <path
            d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75Zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5ZM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75Zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75Zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25Z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      <div class="flex-1">
        <p class="font-medium text-center leading-none !m-0 !text-sm truncate">
          {{ item.name }}
        </p>
      </div>
      <button
        class="h-5 w-5 cursor-pointer flex-shrink-0 !bg-transparent button smooth button--only-icon small primary"
        @click="onShowInfo(item)"
      >
        <svg
          class="h-full w-full"
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="#2196F3"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
            fill="#2196F3"
          />
          <path d="M19 19H23V30H19V19Z" fill="white" />
          <path
            d="M21 16C22.3807 16 23.5 14.8807 23.5 13.5C23.5 12.1193 22.3807 11 21 11C19.6193 11 18.5 12.1193 18.5 13.5C18.5 14.8807 19.6193 16 21 16Z"
            fill="white"
          />
        </svg>
      </button>
    </div>

    <input
      class="w-full !text-sm py-1 text-center"
      type="number"
      min="1"
      :readonly="disabled"
      @focus="$event.target.select()"
      name="strip_quantity"
      id="strip_quantity"
      :value="strip_quantity"
      @input="
        e => {
          let value = e.target.value;
          if (value < 1) value = 1;
          onQuantityChange(value * item.size, item);
        }
      "
    />
    
    <input
      class="w-full !text-sm py-1 text-center"
      type="number"
      min="1"
      :readonly="disabled"
      @focus="$event.target.select()"
      :max="item.stock"
      :value="item.quantity"
      @input="e => onQuantityChange(e.target.value, item)"
      name="quantity"
      id="quantity"
    />

    <input
      class="w-full !text-sm py-1 text-center"
      type="number"
      id="discount"
      :readonly="true"
      name="discount-percent"
      :value="discount_percentage"
    />

    <input
      class="w-full !text-sm py-1 text-center"
      type="number"
      id="discount"
      :readonly="true"
      name="item-discount"
      :value="discount_amount_in_rupee"
    />
  </section>
</template>

<style scoped>
.grid-container-custom {
  grid-template-columns: 35% repeat(4, 1fr);
  gap: 1rem;
}

.grid-container-custom > input {
  margin-bottom: 0 !important;
}

.grid-container-custom > label {
  display: flex !important;
  align-items: center !important;
}
</style>
