<script setup>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { showAlert } from '../../../../store/modules/farmako/util';

const store = useStore();

const isLoading = ref(false);

const state = reactive({
  phone: '',
  orders: [],
});

const onSubmit = async e => {
  e.preventDefault();

  try {
    isLoading.value = true;

    const matcher = /^\d{10}$/;

    if (!state.phone.trim().match(matcher)) {
      showAlert('Phone number is not valid');
      isLoading.value = false;
      return;
    }

    showAlert('Loading...');

    const result = await store.dispatch(
      'farmako/getCustomerOrders',
      state.phone.trim()
    );

    state.orders = result?.orders ?? [];
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const getBillStatusColour = bill_status => {
  if (bill_status === 'billed') {
    return 'px-6 py-4 text-green-600';
  } else if (bill_status === 'cancelled') {
    return 'px-6 py-4 text-red-600';
  }

  return 'px-6 py-4 text-blue-600';
};

const onRedirectToConversation = order_id => {
  return store.dispatch(`farmako/redirect`, order_id);
};
</script>

<template>
  <main class="w-full min-h-screen overflow-auto flex mx-auto p-5 flex-col gap-5">
    <h1 class="text-center text-3xl font-bold col-span-full mb-5">
      Search Order By Phone
    </h1>
    <div
      class="bg-black-900 pt-5 pb-4 px-4 rounded-lg mx-auto w-full max-w-[45rem]"
    >
      <form
        @submit="onSubmit"
        class="flex justify-center w-full gap-3 items-center"
      >
        <div class="w-full relative max-w-[24rem]">
          <input
            class="px-2 py-2.5 w-full"
            type="tel"
            id="phone"
            name="phone"
            v-model="state.phone"
            placeholder="Enter Phone number"
          />

          <svg
            id="search-spinner"
            :class="[
              isLoading ? '' : 'opacity-0',
              'pointer-events-none opacity-1 absolute right-1 top-1.5 animate-spin',
            ]"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10"
              cy="10"
              r="8.5"
              stroke="url(#paint0_radial_9_315)"
              stroke-width="3"
            />
            <defs>
              <radialGradient
                id="paint0_radial_9_315"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10 1.5) rotate(90) scale(17)"
              >
                <stop offset="0.244792" stop-color="#F9FCFF" stop-opacity="0" />
                <stop offset="1" stop-color="#3F99F7" stop-opacity="0.65" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <woot-button
          :disabled="isLoading"
          type="submit"
          color-scheme="success"
          class="col-span-full disabled:bg-opacity-50 block bg-blue-500 px-3 py-2 text-white rounded-md"
        >
          Search
        </woot-button>
      </form>

      <table
        v-if="state.orders.length > 0"
        class="w-full text-center text-sm mt-5 text-gray-500"
      >
        <thead class="text-xs text-gray-700 uppercase bg-black-900">
          <th scope="col" class="px-6 py-3">Order Id</th>
          <th scope="col" class="px-6 py-3">Net Amount</th>
          <th scope="col" class="px-6 py-3">Bill Status</th>
          <th scope="col" class="px-6 py-3">Created At</th>
        </thead>

        <tbody class="divide-y-2 divide-black-900">
          <template :key="item.order_id" v-for="item in state.orders">
            <tr class="bg-black-800/20">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                <button
                  @click="onRedirectToConversation(item.order_id)"
                  class="underline"
                >
                  {{ item.order_id }}
                </button>
              </th>

              <td class="px-6 py-4">
                {{ item.net_amount }}
              </td>

              <td :class="getBillStatusColour(item.bill_status)">
                {{ item.bill_status.toUpperCase() }}
              </td>

              <td class="px-6 py-4">
                {{ !!item.created_at ? item.created_at.split('T')[0] : '' }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </main>
</template>
