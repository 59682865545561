import { emitter } from 'shared/helpers/mitt';
import { BUS_EVENTS } from 'shared/constants/busEvents.js';

export function showAlert(message, action) {
  emitter.emit('newToastMessage', { message, action });
}

export function changeTab(index) {
    return emitter.emit(BUS_EVENTS.CONVERSATION_CHANGE_TAB, index)
}

export function getCookie(cname) {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getHeaders(session) {
  const UNSAFE_HEADERS = [
    'connection',
    'date',
    'transfer-encoding',
    'content-length',
  ];

  const headers = {};

  Object.keys(session).forEach(key => {
    if (UNSAFE_HEADERS.includes(key.toLowerCase())) return;
    headers[key] = session[key];
  });

  return headers;
}

/* Replace of lodash.get function */
export function get(object, keys, defaultVal) {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return get(object, keys.slice(1));
  }
  return object === undefined ? defaultVal : object;
}

/* Replace of lodash.set function */
export function set(object, keys, val) {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  if (keys.length > 1) {
    object[keys[0]] = object[keys[0]] || {};
    return set(object[keys[0]], keys.slice(1), val);
  }
  object[keys[0]] = val;
}

/* Replace of lodash.startCase function */
export function startCase(str) {
  return str
    .split(' ')
    .map(str => {
      const word = str.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

function getEnvironment() {
  switch (window.location.host) {
    case 'ce.farmako.dev': {
      return 'development';
    }
    case 'ce.farmako.tech': {
      return 'staging';
    }
    case 'ce.internal.farmako.app': {
      return 'production';
    }
    case 'ce.farmako.tech:3000': {
      return 'local';
    }
    default: {
      return 'development';
    }
  }
}

export function getApiServerUrl() {
  switch (getEnvironment()) {
    case 'development': {
      return 'https://api.farmako.dev';
    }
    case 'staging': {
      return 'https://api.staging.farmako.app';
    }
    case 'production': {
      return 'https://api.farmako.app';
    }
    case 'local': {
      return 'http://api-staging-service.api-staging.svc.cluster.local';
    }
    default: {
      return 'https://api.farmako.dev';
    }
  }
}
