<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { showAlert } from '../../../../store/modules/farmako/util';

const store = useStore();

const isLoading = ref(false);

const state = reactive({
  phone: '',
  consultations: [],
});

const onFetchDetails = async () => {
  const consultations = await store.dispatch('farmako/getConsultations');
  state.consultations = consultations;
};

onMounted(() => {
  onFetchDetails();
});

const onSubmit = async e => {
  e.preventDefault();

  try {
    isLoading.value = true;

    showAlert('Loading...');

    await store.dispatch('farmako/createConsultation', state.phone);

    showAlert('Consultation created');

    await onFetchDetails();
  } catch (error) {
    console.log(error);
    showAlert(error.message);
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <main class="w-full min-h-screen overflow-auto flex mx-auto p-5 flex-col gap-5">
    <h1 class="text-center text-3xl font-bold col-span-full mb-5">
      Consultations
    </h1>
    <div
      class="mx-auto bg-black-900 pt-5 pb-4 px-4 rounded-lg w-full max-w-[45rem]"
    >
      <form
        @submit="onSubmit"
        class="flex justify-center w-full gap-3 place-items-center"
      >
        <div class="w-full relative">
          <input
            class="px-2 py-2.5 w-full"
            type="tel"
            id="phone"
            name="phone"
            v-model="state.phone"
            placeholder="Enter Phone number"
          />

          <svg
            id="search-spinner"
            :class="[
              isLoading ? '' : 'opacity-0',
              'pointer-events-none opacity-1 absolute right-1 top-1.5 animate-spin',
            ]"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10"
              cy="10"
              r="8.5"
              stroke="url(#paint0_radial_9_315)"
              stroke-width="3"
            />
            <defs>
              <radialGradient
                id="paint0_radial_9_315"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10 1.5) rotate(90) scale(17)"
              >
                <stop offset="0.244792" stop-color="#F9FCFF" stop-opacity="0" />
                <stop offset="1" stop-color="#3F99F7" stop-opacity="0.65" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <woot-button
          :disabled="isLoading"
          type="submit"
          color-scheme="success"
          class="col-span-full disabled:bg-opacity-50 block bg-blue-500 px-3 py-2 text-white rounded-md"
        >
          Create
        </woot-button>
      </form>

      <table
        v-if="state.consultations.length > 0"
        class="w-full text-center text-sm text-gray-500 mt-5"
      >
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <th scope="col" class="px-6 py-3">Patient</th>
          <th scope="col" class="px-6 py-3">Status</th>
          <th scope="col" class="px-6 py-3">Doctor</th>
          <th scope="col" class="px-6 py-3">Assignee</th>
          <th scope="col" class="px-6 py-3">Remark</th>
        </thead>

        <tbody>
          <template :key="item.id" v-for="item in state.consultations">
            <tr class="bg-black-800/20">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                {{ item.patient_name }}
              </th>

              <td class="px-6 py-4">
                {{ item.is_open ? 'OPEN' : 'CLOSED' }}
              </td>

              <td class="px-6 py-4">{{ item.doctor_name }}</td>

              <td class="px-6 py-4">{{ item.assigned_to }}</td>

              <td class="px-6 py-4">
                <textarea
                  readonly
                  :value="item.agent_remark"
                  class="cursor-default"
                >
                </textarea>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </main>
</template>
