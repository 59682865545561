<script setup>
import { effect, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useMapGetter } from 'dashboard/composables/store.js';
import { showAlert } from '../../../../store/modules/farmako/util';

const store = useStore();

const message = ref();
const isLoading = ref(false);
const isDisabled = ref(false);

const items = ref([
  'Farmako',
  'Emoha',
  'Medpay',
  'Healthmitra',
  'Oxyjon',
  'Meradoc',
  'Newmicare',
  'Samarth',
  'QUREX',
  'Visit',
]);

const state = reactive({
  phone: '',
  name: '',
  latitude: '',
  longitude: '',
  business: 'Farmako',
  shipping_charge: 0,
});

const user = useMapGetter('getCurrentUser');
const attrs = user.value?.custom_attributes?.defaultLocation;

effect(() => {
  state.latitude = attrs?.lat;
  state.longitude = attrs?.lng;
});

const onSubmit = async e => {
  try {
    e.preventDefault();

    if (isDisabled.value) {
      return;
    }

    isDisabled.value = true;

    message.value = 'Submitting...';

    const matcher = /^(?:\d{10})?$/;
    if (!state.phone.match(matcher)) {
      showAlert('Phone Number is not valid');
      isLoading.value = true;
      return;
    }

    if (!state.latitude || !state.longitude) {
      showAlert('Please enter valid location.');
      isLoading.value = false;
      return;
    }

    const business =
      state.business === 'Others' ? state.business_other : state.business;

    const result = await store.dispatch('farmako/createOrder', {
      name: state.name,
      phone: '91' + state.phone,
      location: {
        longitude: Number(state.longitude),
        latitude: Number(state.latitude),
      },
      business,
      shipping_charge: state.shipping_charge,
    });

    if (!result?.conversation_id) {
      console.error(result);
      message.value = 'Failed to create a new conversation';
      return showAlert(`Failed to create a new conversation`);
    }

    message.value = `Conversation Created: ${result?.conversation_id}`;

    await store.dispatch('farmako/redirect', result.conversation_id);
  } catch (error) {
    console.log(error);
    message.value = 'Error while creating conversation: ' + error.message;
  } finally {
    isDisabled.value = false;
    isLoading.value = false;
    message.value = '';
  }
};

const onPhoneChange = async e => {
  try {
    const phone_value = e.target.value.trim();

    isLoading.value = true;

    message.value = 'Searching...';

    const matcher = /^(?:\d{10})?$/;

    if (!phone_value.match(matcher)) {
      message.value = '';
      if (phone_value.length >= 10) {
        showAlert('Phone Number is not valid');
      }
      isLoading.value = false;
      return;
    }

    const details = await store.dispatch('farmako/searchCustomer', phone_value);

    state.name = details.name;
    message.value = '';
  } catch (error) {
    console.log(error);
    isLoading.value = false;
    message.value = 'Error occured while searching: ' + error.message;
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <h1 class="text-center text-2xl font-bold py-10 col-span-full">
    Create B2B Order
  </h1>
  <form
    @submit="onSubmit"
    class="p-3 rounded-lg max-w-lg mx-auto grid justify-stretch bg-black-900 w-full flex-1 gap-3 place-items-center"
  >
    <h3 class="text-center text-red-700">{{ message }}</h3>
    <div class="w-full relative">
      <input
        class="px-2 py-1.5 w-full mb-5 rounded-md border border-slate-500"
        type="tel"
        id="phone"
        name="phone"
        required
        v-model="state.phone"
        @input="onPhoneChange"
        placeholder="Enter Phone number"
      />

      <svg
        id="search-spinner"
        :class="[
          isLoading ? '' : 'opacity-0',
          'pointer-events-none opacity-1 absolute right-1 top-1.5 animate-spin',
        ]"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="8.5"
          stroke="url(#paint0_radial_9_315)"
          stroke-width="3"
        />
        <defs>
          <radialGradient
            id="paint0_radial_9_315"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(10 1.5) rotate(90) scale(17)"
          >
            <stop offset="0.244792" stop-color="#F9FCFF" stop-opacity="0" />
            <stop offset="1" stop-color="#3F99F7" stop-opacity="0.65" />
          </radialGradient>
        </defs>
      </svg>
    </div>

    <input
      class="px-2 py-1.5 w-full"
      type="text"
      id="name"
      name="name"
      required
      v-model="state.name"
      placeholder="Enter Customer name"
    />

    <input
      class="px-2 py-1.5 w-full"
      type="text"
      id="latitude"
      name="latitude"
      required
      v-model="state.latitude"
      placeholder="Enter Latitude"
    />

    <input
      class="px-2 py-1.5 w-full"
      type="text"
      id="longitude"
      name="longitude"
      required
      v-model="state.longitude"
      placeholder="Enter Longitude"
    />

    <select
      required
      class="w-full"
      v-model="state.business"
      name="business"
      id="business"
      placeholder="Business"
    >
      <option v-for="(item, index) in items" :key="index" :value="item">
        {{ item }}
      </option>
    </select>

    <input
      class="px-2 py-1.5 w-full"
      type="number"
      id="shipping_charge"
      name="shipping_charge"
      v-model="state.shipping_charge"
      placeholder="Enter Shipping Charge"
    />

    <woot-button
      type="submit"
      size="small"
      color-scheme="success"
      :disabled="isLoading"
      class="col-span-full mx-auto mt-2 max-w-max px-12 py-2.5 disabled:bg-gray-300"
    >
      {{ isLoading ? 'Creating' : 'Create order' }}
    </woot-button>
  </form>
</template>
