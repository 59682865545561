<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import { emitter } from 'shared/helpers/mitt';
import EmailTranscriptModal from './EmailTranscriptModal.vue';
import ResolveAction from '../../buttons/ResolveAction.vue';
import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from 'dashboard/helper/commandbar/events';
import WootButton from '../../ui/WootButton.vue';

export default {
  components: {
    EmailTranscriptModal,
    ResolveAction,
    WootButton,
  },
  data() {
    return {
      showEmailActionsModal: false,
    };
  },
  computed: {
    ...mapGetters({ currentChat: 'getSelectedChat' }),
  },
  mounted() {
    emitter.on(CMD_MUTE_CONVERSATION, this.mute);
    emitter.on(CMD_UNMUTE_CONVERSATION, this.unmute);
    emitter.on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  unmounted() {
    emitter.off(CMD_MUTE_CONVERSATION, this.mute);
    emitter.off(CMD_UNMUTE_CONVERSATION, this.unmute);
    emitter.off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  methods: {
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      useAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      useAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
    async onCallRider() {
      return await this.$store.dispatch('farmako/callRider');
    },
    async onCallCustomer() {
      return await this.$store.dispatch('farmako/callCustomer');
    },
  },
};
</script>

<template>
  <div class="relative flex items-center gap-2 actions--container">
    <woot-button
      v-tooltip="'Call Customer'"
      variant="clear"
      color-scheme="secondary"
      @click="onCallCustomer"
    >
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7769 20C12.9026 20 11.0756 19.5821 9.29595 18.7463C7.51632 17.9106 5.93983 16.8063 4.56646 15.4335C3.19309 14.0607 2.08882 12.4842 1.25364 10.704C0.418462 8.92383 0.000581197 7.09684 0 5.22306C0 4.96152 0.0871794 4.74358 0.261538 4.56922C0.435897 4.39486 0.653846 4.30768 0.915385 4.30768H4.44615C4.64957 4.30768 4.8312 4.37306 4.99103 4.50383C5.15086 4.6346 5.2453 4.8017 5.27436 5.00511L5.84103 8.0564C5.87009 8.25982 5.86631 8.44522 5.82969 8.6126C5.79308 8.77999 5.70967 8.92877 5.57949 9.05896L3.46538 11.1949C4.07564 12.241 4.84224 13.2218 5.76518 14.1372C6.68812 15.0525 7.70144 15.8444 8.80513 16.5128L10.8538 14.4641C10.9846 14.3333 11.1555 14.2351 11.3665 14.1694C11.5774 14.1037 11.7843 14.0857 11.9872 14.1154L14.9949 14.7256C15.1983 14.7692 15.3654 14.8674 15.4962 15.0203C15.6269 15.1731 15.6923 15.351 15.6923 15.5538V19.0846C15.6923 19.3461 15.6051 19.5641 15.4308 19.7384C15.2564 19.9128 15.0385 20 14.7769 20ZM2.63718 9.53845L4.07564 8.09999L3.70513 6.05127H1.76538C1.83803 6.64699 1.93974 7.23546 2.07051 7.81665C2.20128 8.39785 2.39017 8.97178 2.63718 9.53845ZM10.4397 17.341C11.0064 17.588 11.5841 17.7842 12.1729 17.9295C12.7616 18.0748 13.3536 18.1692 13.9487 18.2128V16.2949L11.9 15.8808L10.4397 17.341Z"
          fill="var(--color-woot)"
        />
        <path
          d="M14.1538 0C13.3457 0 12.5455 0.159174 11.7989 0.468434C11.0523 0.777693 10.3739 1.23098 9.80242 1.80242C8.64835 2.95649 8 4.52174 8 6.15385C8 7.78595 8.64835 9.3512 9.80242 10.5053C10.3739 11.0767 11.0523 11.53 11.7989 11.8393C12.5455 12.1485 13.3457 12.3077 14.1538 12.3077C15.7859 12.3077 17.3512 11.6593 18.5053 10.5053C19.6593 9.3512 20.3077 7.78595 20.3077 6.15385C20.3077 5.34571 20.1485 4.54549 19.8393 3.79887C19.53 3.05225 19.0767 2.37386 18.5053 1.80242C17.9338 1.23098 17.2554 0.777693 16.5088 0.468434C15.7622 0.159174 14.962 0 14.1538 0ZM11.12 10.0185C11.3846 9.46462 12.9969 8.92308 14.1538 8.92308C15.3108 8.92308 16.9231 9.46462 17.1877 10.0185C16.3258 10.7052 15.2559 11.0785 14.1538 11.0769C13.0092 11.0769 11.9569 10.6831 11.12 10.0185ZM18.0677 9.12615C17.1877 8.05538 15.0523 7.69231 14.1538 7.69231C13.2554 7.69231 11.12 8.05538 10.24 9.12615C9.58534 8.27361 9.23056 7.22874 9.23077 6.15385C9.23077 3.44 11.44 1.23077 14.1538 1.23077C16.8677 1.23077 19.0769 3.44 19.0769 6.15385C19.0769 7.27385 18.6954 8.30769 18.0677 9.12615ZM14.1538 2.46154C12.96 2.46154 12 3.42154 12 4.61538C12 5.80923 12.96 6.76923 14.1538 6.76923C15.3477 6.76923 16.3077 5.80923 16.3077 4.61538C16.3077 3.42154 15.3477 2.46154 14.1538 2.46154ZM14.1538 5.53846C13.909 5.53846 13.6742 5.44121 13.5011 5.2681C13.328 5.09499 13.2308 4.8602 13.2308 4.61538C13.2308 4.37057 13.328 4.13578 13.5011 3.96267C13.6742 3.78956 13.909 3.69231 14.1538 3.69231C14.3987 3.69231 14.6334 3.78956 14.8066 3.96267C14.9797 4.13578 15.0769 4.37057 15.0769 4.61538C15.0769 4.8602 14.9797 5.09499 14.8066 5.2681C14.6334 5.44121 14.3987 5.53846 14.1538 5.53846Z"
          fill="var(--color-woot)"
        />
      </svg>
    </woot-button>
    <woot-button
      v-tooltip="'Call Rider'"
      variant="clear"
      color-scheme="secondary"
      @click="onCallRider"
    >
      <svg
        width="27"
        height="20"
        viewBox="0 0 27 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7769 20C12.9026 20 11.0756 19.5821 9.29595 18.7463C7.51632 17.9106 5.93983 16.8063 4.56646 15.4335C3.19309 14.0607 2.08882 12.4842 1.25364 10.704C0.418462 8.92383 0.000581197 7.09684 0 5.22306C0 4.96152 0.0871794 4.74358 0.261538 4.56922C0.435897 4.39486 0.653846 4.30768 0.915385 4.30768H4.44615C4.64957 4.30768 4.8312 4.37306 4.99103 4.50383C5.15086 4.6346 5.2453 4.8017 5.27436 5.00511L5.84103 8.0564C5.87009 8.25982 5.86631 8.44522 5.82969 8.6126C5.79308 8.77999 5.70967 8.92877 5.57949 9.05896L3.46538 11.1949C4.07564 12.241 4.84224 13.2218 5.76518 14.1372C6.68812 15.0525 7.70144 15.8444 8.80513 16.5128L10.8538 14.4641C10.9846 14.3333 11.1555 14.2351 11.3665 14.1694C11.5774 14.1037 11.7843 14.0857 11.9872 14.1154L14.9949 14.7256C15.1983 14.7692 15.3654 14.8674 15.4962 15.0203C15.6269 15.1731 15.6923 15.351 15.6923 15.5538V19.0846C15.6923 19.3461 15.6051 19.5641 15.4308 19.7384C15.2564 19.9128 15.0385 20 14.7769 20ZM2.63718 9.53845L4.07564 8.09999L3.70513 6.05127H1.76538C1.83803 6.64699 1.93974 7.23546 2.07051 7.81665C2.20128 8.39785 2.39017 8.97178 2.63718 9.53845ZM10.4397 17.341C11.0064 17.588 11.5841 17.7842 12.1729 17.9295C12.7616 18.0748 13.3536 18.1692 13.9487 18.2128V16.2949L11.9 15.8808L10.4397 17.341Z"
          fill="var(--color-woot)"
        />
        <path
          d="M22.5988 3.78698L18.8024 0H14.6272V1.89349H18.026L19.9195 3.78698H12.2604C9.89349 3.78698 8 5.68047 8 8.04734C8 10.4142 9.89349 12.3077 12.2604 12.3077C14.3621 12.3077 16.1041 10.774 16.445 8.77633L18.4521 7.57396C18.4142 7.73491 18.4142 7.88639 18.4142 8.04734C18.4142 10.4142 20.3077 12.3077 22.6746 12.3077C25.0414 12.3077 26.9349 10.4142 26.9349 8.04734C26.9349 5.68047 25.0414 3.78698 22.6746 3.78698M14.4757 8.76686C14.1538 9.72308 13.2828 10.4142 12.232 10.4142C11.6042 10.4142 11.0022 10.1648 10.5583 9.72096C10.1145 9.27709 9.86509 8.67507 9.86509 8.04734C9.86509 7.41961 10.1145 6.81758 10.5583 6.37371C11.0022 5.92984 11.6042 5.68047 12.232 5.68047C13.2923 5.68047 14.1538 6.38107 14.4757 7.33728H11.787V8.7574L14.4757 8.76686ZM22.6462 10.4142C22.0184 10.4142 21.4164 10.1648 20.9725 9.72096C20.5287 9.27709 20.2793 8.67507 20.2793 8.04734C20.2793 7.41961 20.5287 6.81758 20.9725 6.37371C21.4164 5.92984 22.0184 5.68047 22.6462 5.68047C23.2739 5.68047 23.8759 5.92984 24.3198 6.37371C24.7637 6.81758 25.013 7.41961 25.013 8.04734C25.013 8.67507 24.7637 9.27709 24.3198 9.72096C23.8759 10.1648 23.2739 10.4142 22.6462 10.4142Z"
          fill="var(--color-woot)"
        />
      </svg>
    </woot-button>
    <woot-button
      v-if="!currentChat.muted"
      v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-mute"
      @click="mute"
    />
    <woot-button
      v-else
      v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-1"
      @click="unmute"
    />
    <woot-button
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      variant="clear"
      color-scheme="secondary"
      icon="share"
      @click="toggleEmailActionsModal"
    />
    <ResolveAction
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    />
    <EmailTranscriptModal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
  </div>
</template>

<style scoped lang="scss">
.more--button {
  @apply items-center flex ml-2 rtl:ml-0 rtl:mr-2;
}

.dropdown-pane {
  @apply -right-2 top-12;
}

.icon {
  @apply mr-1 rtl:mr-0 rtl:ml-1 min-w-[1rem];
}
</style>
