<script>
import { mapState, mapGetters } from 'vuex';
import Button from 'dashboard/components/buttons/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      shortbookQuantity: null,
    };
  },
  props: [
    'name',
    'quantity',
    'price',
    'id',
    'item',
    'is_rx_required',
    'manufacturer_name',
    'mrp',
    'content',
    'packing_size',
    'isAvailable',
  ],
  computed: {
    hasSaltContent() {
      return typeof this.content === 'string' && this.content.length > 0;
    },
    isAlreadyAdded() {
      return this.cart.some(i => i.id === this.id);
    },
    ...mapGetters({
      conversation: 'getSelectedChat',
    }),
    ...mapState('farmako', {
      cart: s => s.cart,
      loading: s => s.ui.processing,
      conversation_id: s => s.conversation_id,
    }),
  },
};
</script>

<template>
  <li
    :class="[
      'flex flex-col items-center gap-x-2 px-4 pt-2 pb-3 justify-between rounded-lg relative',
      isAlreadyAdded ? 'bg-woot-400/50' : 'bg-black-900',
    ]"
  >
    <div
      class="flex items-center justify-end absolute w-full gap-x-4 right-0 top-0"
    >
      <woot-label
        small
        v-if="is_rx_required"
        title="Prescription Required"
        color-scheme="alert"
        class="!rounded-none !m-0"
      />

      <woot-label
        small
        :title="isAvailable ? 'Available' : 'Not Available'"
        :color-scheme="isAvailable ? 'success' : 'alert'"
        class="!rounded-none !m-0"
      />
    </div>
    <div class="w-full font-sans mt-3">
      <p class="!text-base font-medium">{{ name }}</p>
      <p class="!text-sm">Salt: {{ content }}</p>
      <p class="!text-sm">MRP: {{ mrp }}</p>
      <p class="!text-sm">BY: {{ manufacturer_name }}</p>
      <p class="!text-sm">PACKAGE SIZE: {{ packing_size }}</p>
    </div>
    <div class="flex items-end gap-2 justify-between w-full">
      <div class="flex items-center gap-x-2">
        <input
          placeholder="Pack QTY"
          type="number"
          class="!w-28 !mb-0"
          v-model="shortbookQuantity"
        />
        <woot-button
          size="small"
          :disabled="!shortbookQuantity || loading"
          @click="
            $emit('add-to-shortbook', {
              item,
              quantity: shortbookQuantity,
              conversation_id: conversation.id,
            })
          "
        >
          Add to shortbook
        </woot-button>
      </div>

      <div class="flex items-center gap-x-2">
        <woot-button size="small" @click="$emit('send-medicine-detail', item)">
          Send medicine detail
        </woot-button>
        <woot-button
          size="small"
          :disabled="!hasSaltContent"
          @click="$emit('get-alternate', item)"
        >
          Find alternate
        </woot-button>

        <woot-button
          size="small"
          :disabled="!isAvailable"
          :color-scheme="isAvailable ? 'success' : 'alert'"
          @click="$emit('add-to-cart', item)"
        >
          Add to cart
        </woot-button>
      </div>
    </div>
  </li>
</template>
