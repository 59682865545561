<script setup>
import { onMounted, reactive } from 'vue';
import { useMapGetter, useStore } from 'dashboard/composables/store.js';
import Search from './Search.vue';
import Order from './Order.vue';
import CartItem from './CartItem.vue';
import BatchSelector from './BatchSelector.vue';

const emit = defineEmits(['load']);

const store = useStore();

const serving_pharmacy = useMapGetter('farmako/pharmacy');
const cart = useMapGetter('farmako/cart');
const user = useMapGetter('getCurrentUser');

const onLoad = async () => {
  emit('load');

  await store.dispatch('farmako/fetchOrderDetails');
};

onMounted(() => {
  onLoad();
});
</script>

<template>
  <main class="p-3 space-y-5 md:p-6 min-h-screen overflow-auto">
    <h1 class="font-bold text-center text-primary text-2xl">
      {{ serving_pharmacy }} Store
    </h1>
    <Search />
    <section
      class="flex flex-col rounded-md bg-slate-100 dark:bg-black-900 p-4"
    >
      <section
        class="w-full grid grid-container-custom text-xs font-medium font-mono text-center"
      >
        <p class="">Name</p>
        <p class="">QTY (Strip)</p>
        <p class="">QTY (Loose)</p>
        <p class="">Discount (%)</p>
        <p class="">Discount (₹)</p>
      </section>

      <p
        v-if="cart.length < 1"
        class="text-center font-medium text-base mt-2 text-red-400"
      >
        Virtual RX is empty
      </p>

      <ul
        id="rx-item-list"
        class="flex flex-col ml-0 justify-center w-full gap-3 pt-2"
      >
        <li
          v-for="item in cart"
          class="flex w-full items-center"
          :key="item.medicine_id + item.batch"
        >
          <CartItem
            :item="item"
            :agent_type="user.custom_attributes.agent_type"
          />
        </li>
      </ul>
    </section>
    <Order />
  </main>
  <BatchSelector />
</template>

<style scoped>
.grid-container-custom {
  grid-template-columns: 35% repeat(4, 1fr);
  gap: 1rem;
}
</style>
